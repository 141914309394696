@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.container {
  max-width: 1024px;
  margin: 0 auto;
}
.link-button {
  display: inline-block;
  width: 100%;
  max-width: 260px;
  line-height: 43px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: #01102d;
  border-radius: 7px;
  border: none;
  outline: none;
  background-color: #ffffff;
  transition: 0.4s;
}
.link-button:focus,
.link-button:hover {
  color: #105189;
  background-color: #f1f1f1;
}

/* HOME */
.home {
  background-color: #012849;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
@supports (
  background: transparent
    radial-gradient(closest-side at 78% 16%, #105189 -20%, #012849 200%) 0% 0%
    no-repeat padding-box
) {
  .home {
    background: transparent
      radial-gradient(closest-side at 78% 16%, #105189 -20%, #012849 200%) 0% 0%
      no-repeat padding-box;
  }
}
.home header {
  padding: 36px 0;
}
.home header img {
  display: block;
  width: 168px;
  height: auto;
  margin: auto;
}
.home section {
  margin-bottom: auto;
}
.home section h1 {
  color: #ffffff;
  font-size: 2.188rem;
  line-height: 60px;
  text-align: center;
  margin-bottom: 36px;
}
.home section .link-container {
  text-align: center;
}
.home .link-button:first-child {
  margin-right: 8px;
}
.home footer {
  color: #ffffff;
  text-align: center;
  padding-bottom: 37px;
}
.home footer .footer-header {
  flex-direction: column;
}
.home footer img {
  display: inline-block;
  width: 80px;
  height: auto;
}
.home footer p.slogan {
  font-size: 0.875rem;
  margin: 5px 0 22px;
  letter-spacing: 0.35px;
}
.home footer p.copyright {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 200;
  line-height: 16px;
  letter-spacing: 0.3px;
}

/* MAIN-HEADER */
#main-header {
  background-color: #012849;
}
@supports (
  background: transparent
    radial-gradient(closest-side at 78% 16%, #105189 -20%, #012849 200%) 0% 0%
    no-repeat padding-box
) {
  #main-header {
    background: transparent
      radial-gradient(closest-side at 78% 16%, #105189 -20%, #012849 200%) 0% 0%
      no-repeat padding-box;
  }
}
#main-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  height: 80px;
}
#main-header .logo {
  outline: none;
  transition: 0.3s;
}
#main-header .logo:focus,
#main-header .logo:hover {
  opacity: 0.6;
}
#main-header .logo img {
  width: 76px;
  height: auto;
}
#main-header h1 {
  font-size: 1.25rem;
}
#main-header h1 br {
  display: none;
}
.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27px;
  height: 18px;
  border: none;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}
.burger:focus,
.burger:hover {
  opacity: 0.5;
}
.burger img {
  width: 27px;
  height: 18px;
}

/* SECTION-CONTENT */
.policies {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.policy {
  margin-bottom: auto;
}
.policy > .container {
  padding: 80px 0 58px;
  max-width: 720px;
}
.policy .title {
  font-size: 1.75rem;
  margin-bottom: 24px;
  color: #01102d;
}
.policy .subtitle {
  font-size: 1rem;
  margin-bottom: 22px;
  color: #292929;
}
.policy .date {
  margin-bottom: 24px;
}
.policy p.content {
  font-size: 1rem;
  line-height: 25px;
  letter-spacing: 0.16px;
  margin-bottom: 22px;
}
.policy a {
  color: #147ce7;
  text-decoration: underline;
}
.policy a:after {
  content: "";
  display: inline-block;
  background-image: url(/static/media/external-link.0ac14670.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}

/* MAIN-FOOTER */
#main-footer {
  text-align: center;
  padding: 18px 0 28px;
  background-color: #012849;
}
#main-footer .footer-header {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 20px;
}
#main-footer img {
  display: block;
  width: 74px;
  height: auto;
}
#main-footer p.slogan {
  color: #ffffff;
  font-size: 0.875rem;
  letter-spacing: 0.35px;
  text-align: left;
}
#main-footer p.copyright {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 200;
  line-height: 16px;
  letter-spacing: 0.3px;
}
#main-footer p.copyright br {
  display: none;
}

/* NOT-FOUND */
.not-found {
  background: #012849;
}
@supports (
  background: transparent
    radial-gradient(closest-side at 78% 16%, #105189 -20%, #012849 200%) 0% 0%
    no-repeat padding-box
) {
  .not-found {
    background: transparent
      radial-gradient(closest-side at 78% 16%, #105189 -20%, #012849 200%) 0% 0%
      no-repeat padding-box;
  }
}
.not-found > .container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.not-found header {
  padding: 36px 0;
}
.not-found header img {
  display: block;
  width: 168px;
  height: auto;
  margin: auto;
}
.not-found section {
  text-align: center;
  font-weight: 600;
}
.not-found section p {
  color: #ffffff;
  font-size: 6.25rem;
  font-weight: 300;
  text-align: center;
}
.not-found section h1 {
  font-size: 2.25rem;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 42px;
}
.not-found section .link-container {
  text-align: center;
}
.not-found .link-button {
  color: #ffffff;
  border-radius: 8px;
  font-size: 0.75rem;
  text-transform: uppercase;
  background-color: #0072ff;
}

/* SIDEBAR */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 4;
  transition: 0.4s;
  background-color: transparent;
  pointer-events: none;
}
.sidebar .content {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 424px;
  padding: 58px 0 45px 180px;
  margin: auto;
  transition: 0.3s;
  background-color: #011631;
  -webkit-transform: translateY(-424px);
          transform: translateY(-424px);
}
/* animation */
.sidebar.active {
  pointer-events: all;
}
.sidebar.active .content {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
/* animation */
.sidebar .close {
  position: absolute;
  top: 24px;
  right: 147px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  background-color: unset;
  transition: 0.2s;
  cursor: pointer;
  outline: none;
}
.sidebar .close:focus,
.sidebar .close:hover {
  opacity: 0.6;
}
.sidebar .close img {
  width: 16px;
  height: 16px;
}
.sidebar .content div:first-of-type {
  margin-right: 57px;
}
.sidebar h3 {
  font-size: 0.875rem;
  color: #ffffff;
  margin-bottom: 20px;
}
.sidebar ul li {
  position: relative;
  margin-bottom: 10px;
}
.sidebar ul li:last-child {
  margin-bottom: 0;
}
.sidebar ul li::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -15px;
  display: block;
  border-left: 5px solid #0072ff;
}
.sidebar a {
  font-size: 0.75rem;
  line-height: 27px;
  font-weight: 200;
  color: #ffffff;
  outline: none;
  transition: 0.3s;
}
.sidebar a.active {
  color: #0072ff;
}
.sidebar a:focus,
.sidebar a:active,
.sidebar a:hover {
  color: #4899fc;
}

@media (max-width: 768px) {
  .container {
    margin: 0 20px;
  }
  .home header {
    padding: 30px 0 40px;
  }
  .home header img {
    width: 107px;
  }
  .home section h1 {
    font-size: 1.625rem;
    line-height: 33px;
    margin-bottom: 67px;
  }
  .home .link-button {
    max-width: 286px;
    margin: 0 auto;
  }
  .home .link-button:first-child {
    margin-right: auto;
    margin-bottom: 16px;
  }
  .home footer {
    padding-bottom: 26px;
  }

  /* MAIN-HEADER */
  #main-header h1 {
    font-size: 1rem;
    margin: 0 auto 0 24px;
  }
  #main-header h1 br {
    display: block;
  }
  @supports (
    background: transparent
      radial-gradient(closest-side at 50% 50%, #105189 0%, #012849 100%) 0% 0%
      no-repeat padding-box
  ) {
    #main-header {
      background: transparent
        radial-gradient(closest-side at 50% 50%, #105189 0%, #012849 100%) 0% 0%
        no-repeat padding-box;
    }
  }

  /* POLICY */
  .policy > .container {
    padding-top: 24px;
    padding-bottom: 15px;
  }
  .policy .title {
    font-size: 1.25rem;
  }
  .policy p.content {
    font-size: 0.875rem;
    line-height: 22px;
  }

  /* MAIN-FOOTER */
  #main-footer p.copyright br {
    display: block;
  }

  /* NOT-FOUND */
  .not-found header {
    padding-bottom: 76px;
  }
  .not-found section p {
    font-size: 3.625rem;
    line-height: 58px;
    margin-bottom: 10px;
  }
  .not-found section h1 {
    font-size: 1.75rem;
  }

  /* SIDEBAR */
  .sidebar .content {
    flex-direction: column;
    padding: 44px;
    height: 100vh;
    overflow: scroll;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
  .sidebar.active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .sidebar .content div:first-of-type {
    margin-right: 0;
    margin-bottom: 22px;
  }
  .sidebar .close {
    right: 27px;
  }
}
@media (max-width: 320px) {
  .not-found section h1 {
    font-size: 1.5rem;
  }
}

